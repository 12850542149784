import React from 'react';
import { SummarySection } from '@solublestudio/legalbrave-design-system';

export default function LawyersSection({ anchor, items, ...props }) {
    const parseCards = items.map((c) => ({
        ...c,
        typename: 'card_photo',
    }));
    const parseProps = {
        ...props,
        cards: parseCards,
    };
    return <SummarySection items={[parseProps]} anchor={anchor} />;
}
